<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <label
            for="datepicker-start"
          >Data de início
          </label>
          <b-button
            v-if="filters.startDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{filters.startDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-start"
            v-model="filters.startDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="datepicker-end"
          >Data de fim</label>
          <b-button
            v-if="filters.endDate"
            variant="danger"
            size="sm"
            class="ml-1 btn-icon py-0"
            @click="()=>{filters.endDate = ''; filterEvents();}"
          >
            <feather-icon
              icon="XIcon"
              class="my-05"
            />
          </b-button>
          <flat-pickr
            id="datepicker-end"
            v-model="filters.endDate"
            class="form-control"
            :config="config"
            @on-change="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="professor"
          >Professores</label>
          <v-select
            id="professor"
            v-model="filters.professors"
            multiple
            label="full_name"
            placeholder="Todos os professores"
            :reduce="p => p.id"
            :options="$store.state.quales.professors"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="cliente"
          >Clientes</label>
          <v-select
            id="cliente"
            v-model="filters.clients"
            multiple
            label="full_name"
            placeholder="Todos os clientes"
            :reduce="p => p.id"
            :options="$store.state.quales.clients"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="modalidade"
          >Modalidade</label>
          <v-select
            id="modalidade"
            v-model="filters.modalities"
            multiple
            label="name"
            placeholder="Todas as modalidades"
            :reduce="p => p.id"
            :options="$store.state.quales.modalities"
            @input="filterEvents"
          />
        </b-col>
        <b-col md="4">
          <label
            for="status"
          >Status</label>
          <v-select
            id="status"
            v-model="filters.status"
            label="title"
            :reduce="o => o.value"
            :clearable="false"
            :options="statuses"
            @input="filterEvents"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <h4 class="card-title">
            Fluxo de aulas
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="text-left text-md-right mb-1 mb-md-0"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            @click="toggleSorting()"
          >
            <feather-icon
              v-if="filters.ordering === '-date_time'"
              icon="ArrowUpIcon"
              size="14"
            />
            <feather-icon
              v-else
              icon="ArrowDownIcon"
              size="14"
            />
          </b-button>
        </b-col>
      </b-row>
      <app-timeline>

        <app-timeline-item
          v-for="event in $store.state.quales.events"
          :key="event.id"
          :variant="event.validated ? (event.canceled ? 'primary':'success') : 'danger'"
          icon="UserIcon"
        >

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <h6>
              <b-link
                :to="{ name: 'event-edit', params: { id: event.id } }"
                class="link-to-event"
              >
                Aula de {{ event.modality_name }} com {{ event.professor_name }}
              </b-link>
            </h6>
            <small class="text-muted">{{ dateFormater.format(new Date(event.date_time)) }}</small>
          </div>
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
            <div />
            <b-badge
              pill
              variant="light-primary"
            >
              {{ event.local_name }}
            </b-badge>
          </div>

          <div
            v-if="event.canceled"
          >
            Aula cancelada : {{ $store.state.quales.cancellation.find(item => item.value === event.canceled).text }}
          </div>
          <b-button
            v-else
            v-b-toggle="'students-list-'+event.id"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            size="sm"
            variant="outline-primary"
          >
            {{ event.students_present.length }} alunos presentes
          </b-button>
          <b-collapse
            v-if="event.students_present.length"
            :id="'students-list-'+event.id"
          >
            <b-list-group
              flush
              class="mt-1"
            >
              <b-list-group-item
                v-for="student in event.students_present"
                :key="student.id"
                class="d-flex justify-content-between align-items-center bg-transparent"
              >
                <span>{{ student.full_name }}</span>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
          <p class="mt-1">
            {{ event.observations }}
          </p>

        </app-timeline-item>

      </app-timeline>
      <div class="d-flex justify-content-between flex-wrap">
        <div>
          <b-pagination
            v-model="filters.page"
            :value="1"
            :total-rows="total"
            :per-page="10"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BBadge, VBTooltip, BCard,
  BRow, BCol, BLink, BPagination,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCard,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BBadge,
    BRow,
    BCol,
    BLink,
    vSelect,
    BPagination,
    flatPickr,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      selected: null,
      option: [],
      filters: {
        startDate: '',
        endDate: '',
        professors: [],
        clients: [],
        modalities: [],
        status: false,
        page: 1,
        ordering: 'date_time',
      },
      total: 0,
      statuses: [
        {
          title: 'Qualquer status',
          value: null,
        },
        {
          title: 'Validadas',
          value: true,
        },
        {
          title: 'Não validadas',
          value: false,
        },
      ],
      config: {
        allowInput: false,
        locale: Portuguese,
      },
    }
  },
  created() {
    this.dateFormater = new Intl.DateTimeFormat('pt-br', {
      hour12: false,
      year: 'numeric',
      day: 'numeric',
      month: 'long',
      hour: 'numeric',
      minute: '2-digit',
      // weekday: 'long',
      timeZone: 'America/Sao_Paulo',
    })
    this.$store.dispatch('quales/getEvents', this.filters).then(result => {
      this.total = result.count
    })
      .catch(error => {
        if (error.message === 'Not logged in') {
          this.$store.dispatch('user/logout')
          this.$router.push({ name: 'auth-login' })
        }
      })
    this.$store.dispatch('quales/getProfessors')
    this.$store.dispatch('quales/getClients')
    this.$store.dispatch('quales/getModalities')
  },
  methods: {
    filterEvents() {
      this.filters.page = 1
      this.$store.dispatch('quales/getEvents', this.filters).then(result => {
        this.total = result.count
      })
        .catch(error => {
          if (error.message === 'Not logged in') {
            this.$store.dispatch('user/logout')
            this.$router.push({ name: 'auth-login' })
          }
        })
    },
    changePage() {
      this.$store.dispatch('quales/getEvents', this.filters).then(result => {
        this.total = result.count
      })
        .catch(error => {
          if (error.message === 'Not logged in') {
            this.$store.dispatch('user/logout')
            this.$router.push({ name: 'auth-login' })
          }
        })
    },
    toggleSorting() {
      if (this.filters.ordering === 'date_time') {
        this.filters.ordering = '-date_time'
      } else {
        this.filters.ordering = 'date_time'
      }
      this.filterEvents()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~flatpickr/dist/themes/airbnb.css";
.link-to-event{
  color: #5e5873;
}
.form-control[readonly] {
  background-color: inherit;
}
</style>
